import { environment } from '../environments/environment';

 var server = environment.apiUrl;
server ="/gbe/api/";


export const URL = {
  SERVER: server,
  

  //login - user
  admin_login_auth: server + 'adminAuth/checkAuth', 
  admin_reset_pwd: server + 'adminlogin/reset/resetPwd', 
  create_account: server + 'adminlogin/insert/createAccount', 
  getAllAdminData: server + 'adminlogin/getAllAdminData', 
  forgot_password: server + 'adminlogin/forgot/forgotpwd', 
  deleteAdmin: server + 'adminlogin/delete/deleteRow',  
  editAdmin: server + 'adminlogin/edit/editRow',  
  update_userName: server + 'adminlogin/updateUserName', 
  requestAdminPasswordReset: server + 'requestAdminPasswordReset/',  
   
  
  getUsersList: server + 'userListTab/getAllData', 
  getUserFetchList: server + 'userListTab/getFetchData', 
  addUser: server + 'userListTab/insert/insertRow', 
  editUser: server + 'userListTab/edit/editRow', 
  deleteUser: server + 'userListTab/delete/deleteRow', 

  userApproval: server + 'userListTab/userApproval', 
  userLoginAttempt: server + 'userListTab/userLoginAttempt', 
  sendMailUser: server + 'userListTab/sendMailUser', 
  VerifyEmailAddress: server + 'VerifyEmailAddress/', 

  
  getPartsList: server + 'partsDetailsTab/getAllData', 
  getPartsFetchList: server + 'partsDetailsTab/getFetchData', 
  addPart: server + 'partsDetailsTab/insert/insertRow', 
  editPart: server + 'partsDetailsTab/edit/editRow', 
  deletePart: server + 'partsDetailsTab/delete/deleteRow', 
  fetchPartList: server + 'partsDetailsTab/fetchPartList',
  fetch701PartList: server + 'partsDetailsTab/fetch701PartList',
  validatePartNo: server + 'validatePartNo',

  getDocumentsList: server + 'documentListTab/getAllData', 
  getDropdownDocList: server + 'documentListTab/getDropdownDocList', 
  getDocumentsFetchList: server + 'documentListTab/getFetchData',  
  addDocList: server + 'documentListTab/insert/insertRow', 
  editDocList: server + 'documentListTab/edit/editRow', 
  deleteDocList: server + 'documentListTab/delete/deleteRow', 
  getPartDropdwon: server + 'partsDetailsTab/getDropdown', 
  
  getDocumentsDetails: server + 'documentDetailsTab/getAllData', 
  getDocumentsFetchDetails: server + 'documentDetailsTab/getFetchData',  
  addDocDetails: server + 'documentDetailsTab/insert/insertRow', 
  editDocDetails: server + 'documentDetailsTab/edit/editRow', 
  deleteDocDetails: server + 'documentDetailsTab/delete/deleteRow', 

  customerProfileRequestTab: server + 'customerProfileRequestTab/getAllData', 
  customerProfileRequestTabInsert: server + 'customerProfileRequestTab/insert/insertRow', 
  customerProfileRequestTabEditrow: server + 'customerProfileRequestTab/edit/editRow', 
  customerProfileRequestTabDeleterow: server + 'customerProfileRequestTab/delete/deleteRow',
  getLatestHistoryRequestTab: server + 'customerProfileRequestTab/latestHistoryTab', 
  searchCustomerRequestProfile: server + 'customerProfileRequestTab/searchCustomerProfile', 
  searchCustomerRequestProStatus: server + 'customerProfileRequestTab/searchCustomerProStatus',
  getDropdownRequestData: server + 'customerProfileRequestTab/getDropdownData',

  customerProfileTab: server + 'customerProfileTab/getAllData', 
  customerProfileTabInsert: server + 'customerProfileTab/insert/insertRow', 
  customerProfileTabEditrow: server + 'customerProfileTab/edit/editRow', 
  customerProfileTabDeleterow: server + 'customerProfileTab/delete/deleteRow',
  getLatestHistoryTab: server + 'customerProfileTab/latestHistoryTab', 

  customerProfileTabDocApproval: server + 'customerProfileTab/docApproval', 
  CustomerProfileStatus: server + 'customerProfileTab/CustomerProfileStatus', 
  searchCustomerProfile: server + 'customerProfileTab/searchCustomerProfile', 
  searchCustomerProStatus: server + 'customerProfileTab/searchCustomerProStatus',
  getDropdownData: server + 'customerProfileTab/getDropdownData',
  getDropdownDataStatus: server + 'customerProfileTab/getDropdownDataStatus',
  objExpiryCount: server + 'customerProfileTab/expiryDateCount',
  getUserExpiryCount: server + 'userListTab/expiryDateCount',
  
  getCustomerInfo: server + 'customerInfoTab/getAllData', 
  getCustomerFetchInfo: server + 'customerInfoTab/getFetchData',  
  addCusInfo: server + 'customerInfoTab/insert/insertRow', 
  editCusInfo: server + 'customerInfoTab/edit/editRow', 
  deleteCusInfo: server + 'customerInfoTab/delete/deleteRow', 
  requestCustomerExists: server + 'customerInfoTab/requestCustomerExists', 
  
  auditLogDetails: server + 'auditLogDetails/getAllData/', 
  auditLogDetails_fecthAudit: server + 'auditLogDetails/fecthAudit/getSingleAudit/',  
  auditSingleAudit: server + 'auditLogDetails/getSingleAudit/', 
  auditLogFetchDetails: server + 'auditLogDetails/getFetchData/',
  customerAnalytics: server + 'customerAnalytics/getAllData/',  
  customerAnalyticsSearch: server + 'customerAnalytics/search/',  

 getAllDocClassWithSIL: server + 'getAllDocClassWithSIL/', 
  getAllParts: server + 'getAllParts/', 

  customerPartsTab: server + 'customerPartsTab/getAllData', 
  getCustomerFetchParts: server + 'customerPartsTab/getFetchData',  
  addCusPart: server + 'customerPartsTab/insertRow', 
  editCusPart: server + 'customerPartsTab/editRow', 
  deleteCusPart: server + 'customerPartsTab/deleteRow', 
  customerPartStatus: server + 'customerPartsTab/customerPartStatus', 
  customerPartExpiryStatus: server + 'customerPartsTab/customerPartExpiryStatus', 
  getUserPartList: server + 'customerPartsTab/getUserPartList', 

  customerDocumentsTab: server + 'customerDocumentsTab/getAllData', 
  getCustomerFetchDocuments: server + 'customerDocumentsTab/getFetchData',  
  addCusDoc: server + 'customerDocumentsTab/insertRow', 
  editCusDoc: server + 'customerDocumentsTab/editRow', 
  deleteCusDoc: server + 'customerDocumentsTab/deleteRow', 
  CustomerDocStatus: server + 'customerDocumentsTab/CustomerDocStatus', 
  downloadFile: server + 'downloadFile/',

  getPublicationList: server + 'admin/publicationList'
    
};

export class Customer {     
    CUSTOMER_ID: string;
	APPROVER_ID?: string;
	CUSTOMER_NAME: string;	
	STATUS?: string;
	ACCOUNT_TYPE?: string;
	SOURCE?: string;
		
}
export class CustomerPart {     
    CUSTOMER_ID?: string;  
    CUSTOMER_NAME?: string;
    PART_NO?: string;
    CHECK_PART_NO?: boolean = true;
	START_DATE?: string;
	EXPIRY_DATE?: string;
	NOTES?: string;	
	STATUS?: string;
	SOURCE?: string;	
	APPROVER_ID?: string;	
	UPDATED_DATE?: string;
	UPDATED_BY?: string;	
	APPROVAL_DATE?: string;	
	EXTEND_EXPIRY?: string;	
	DOC_CLASS_LIST?: any;	
	REQUEST_TYPE?: any;	
  	CUSTOMER_TEXT?: string;
	  START_DATE_DISPLAY?: string;
	EXPIRY_DATE_DISPLAY?: string;
}
export class CustomerDoc {     
    CUSTOMER_ID?: string;     
    CUSTOMER_NAME?: string;
    PART_NO?: string;
	STATUS?: string;
	SOURCE?: string;	
	APPROVER_ID?: string;	
	APPROVAL_DATE?: string;	
	DOC_CLASS?: string;	
}


export class Part {     
    PART_NO: string = '';
	PART_MOD?: string = '';
	MOD_REV?: string = '';
	DESCRIPTION?: string  = '';	 
	START_DATE?: string  = '';	 
	EXPIRY_DATE?: string  = '';	 
	NOTES?: string  = '';
	DOC_LIST_DESC?: string  = '';
	DOC_LIST?: Document[]  = [];
	ACTION?: string  = '';
	CR_STATUS?: number  = 0;
}

export class PartDetails{
	PART_NO: string = '';
	PART_MOD?: string = '';
	COMPONENT_PART?: string = '';
	DRAW_POS_NO?: string = '';
	QTY_PER_ASSEMBLY?: number;
	DESCRIPTION?: string = '';
	NOTES_TXT?: string = '';
	SOURCE?: string = '';
	APPROVER_ID?: string = '';
}



import { Routes, RouterModule } from '@angular/router'; 
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component'; 
import { LoginComponent } from './customer/login';
import { VerifyemailidComponent } from './shared/components/verifyemailid/verifyemailid.component';
import { ResetpasswordComponent } from './shared/components/resetpassword/resetpassword.component'; 
import { SignInComponent } from './admin/sign-in/sign-in.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent} , 
    { path: 'admin', component: SignInComponent} , 
	{ path: 'verifyemailid/:id', component: VerifyemailidComponent} , 
	{ path: 'resetpassword/:id', component: ResetpasswordComponent} , 
	{ path: 'resetadminpassword/:id', component: ResetpasswordComponent} , 
	// { path: 'adminlogin', component: LoginComponent  } , 
	{
		path: 'customer', 
		loadChildren: './customer/customer.module#CustomerModule'
	} ,{
		path: 'admin', 
		loadChildren: './admin/admin.module#AdminModule'
	} ,
  {
	path: '',
	component: AppComponent,
	children: [
	  { path: 'customer', 
	  loadChildren: './customer/customer.module#CustomerModule' }  ,
	  { path: 'admin', 
	  loadChildren: './admin/admin.module#AdminModule' }  
	]
  }
];

 @NgModule({
   imports: [RouterModule.forRoot(routes, { useHash: true })],
   exports: [RouterModule]
 })
 export class appRoutingModule { }

//export const appRoutingModule = RouterModule.forRoot(routes);

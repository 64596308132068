<div class="page_content"> 
 <div class="col-md-12 outer-box" >
    <img src="/assets/gables-color-logo-400.png" >
	
	<div class="col-md-12 entry-box">
		<div class="col-md-12 entry-inner-box">
			  <label class="control-label col-md-3" for="new_user_id">User ID</label>
			  <div class="col-md-6">
				<input type="textbox" name="new_user_id" class="form-control" [(ngModel)]="objUser.USER_ID"   /> 
			</div>
		</div> 
		 <div class="col-md-12 entry-inner-box"> 
			   <label class="control-label  col-md-3" for="new_pass">Password</label> 
			   <div class="col-md-6"> 
				 <input type="password" name="new_pass" class="form-control" [(ngModel)]="objUser.PASSWORD"   />  
			 </div> 
		 </div> 
		 <div class="col-md-12 entry-inner-box"> 
			   <label class="control-label  col-md-3" for="new_confirm_pass">Confirm Password</label> 
			   <div class="col-md-6"> 
				 <input type="password" name="new_confirm_pass" class="form-control" [(ngModel)]="confirmPass"   />  
			 </div> 
		 </div> 
	</div>
	 <div class="col-md-9">
		 <button type="button" class="btn btn-primary pull-right"(click)="updatePassword()" >Submit</button> 
        </div>
	
 <!-- <div *ngIf="verifySuccess" class="col-md-12 mesage-box success">  -->
	<!-- <div> -->
	<!-- <a href="javascript:void(0)" class="text-link" routerLink='/login'> Navigate to login page</a> -->
	<!-- </div> -->
<!-- </div> -->

<!-- <div *ngIf="verifyFailed" class="col-md-12  mesage-box error"> -->
	<!-- <h2>Error setting password. Please try again later.</h2> -->
<!-- </div>  -->


	
</div>	
</div>	
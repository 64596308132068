<div class="fusion-footer">

	<footer class="fusion-footer-widget-area fusion-widget-area">
		<div class="fusion-row footer-outer">
			<div class="fusion-columns fusion-columns-4 fusion-widget-area">

				<div class="fusion-column col-lg-3 col-md-3 col-sm-3">
					<section id="contact_info-widget-5" class="fusion-footer-widget-column widget contact_info">
						<h4 class="widget-title" data-fontsize="12" data-lineheight="18">Gables Engineering, Inc.</h4>
						<div class="contact-info-container">
							<p class="address">247 Greco Ave.</p>

							<p class="phone">Phone: 305-774-4400</p>



							<p class="email">Email: <a href="mailto:sales@gableseng.com">sales@gableseng.com</a></p>

							<p class="web">Web: <a href="http://www.gableseng.com">
									www.gableseng.com </a></p>
						</div>
						<div style="clear:both;"></div>
					</section>
				</div>
				<div class="fusion-column col-lg-3 col-md-3 col-sm-3">
					<section style="margin-top: 10px;" id="text-15"
						class="fusion-footer-widget-column widget widget_text">
						<div class="textwidget">
							<p><a href="https://gableseng.typeform.com/to/GTO4XGFp">How Are We Doing?</a></p>
							<p style="display:none"><a href="https://portlet1.gableseng.com">Portal Login</a></p>
						</div>
						<div style="clear:both;"></div>
					</section>
				</div>
				<div class="fusion-column col-lg-3 col-md-3 col-sm-3">
					<section style="margin-top: 10px;" id="text-14"
						class="fusion-footer-widget-column widget widget_text">
						<div class="textwidget">
							<p><a href="mailto: sales@gableseng.com">Sales</a></p>
							<p><a href="mailto: albertg@gableseng.com">Engineering</a></p>
							<p><a href="mailto: support@gableseng.com">Support</a></p>
							<p><a href="mailto: repairs@gableseng.com">Repairs</a></p>
							<p>AOG +1.305.541.1233</p>
						</div>
						<div style="clear:both;"></div>
					</section>
				</div>
				<div class="fusion-column fusion-column-last col-lg-3 col-md-3 col-sm-3">
					<section style="margin-top: 10px;" id="text-16"
						class="fusion-footer-widget-column widget widget_text">
						<div class="textwidget">
							<p><a href="https://www.gableseng.com/gei/ads-b-air-transport/">ADS-B – Air Transport</a>
							</p>
							<p><a href="https://www.gableseng.com/gei/adsb-brga/">ADS-B – Business / Regional</a></p>
						</div>
						<div style="clear:both;"></div>
					</section>
				</div>

				<div class="fusion-clearfix"></div>
			</div> <!-- fusion-columns -->
		</div> <!-- fusion-row -->
	</footer> <!-- fusion-footer-widget-area -->


	<footer id="footer" class="fusion-footer-copyright-area">
		<div class="fusion-row">
			<div class="fusion-copyright-content">

				<div class="fusion-copyright-notice" style="padding-bottom: 0px;">
					<div style="margin-left: 110px;">
						Copyright 2018 Gables Engineering | All Rights Reserved </div>
				</div>
				<div class="to-top-container to-top-right">
					<a href="javascript:void(0)" id="toTop" (click)="topFunction()">
						<i class="fa fa-angle-up" aria-hidden="true"></i>
						<span id="toTopHover" style="opacity: 0;"></span>
						<!-- <span class="screen-reader-text">Go to Top</span> -->
					</a>
				</div>

			</div> <!-- fusion-fusion-copyright-content -->
		</div> <!-- fusion-row -->
	</footer> <!-- #footer -->
</div>
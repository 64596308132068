import { Component } from '@angular/core';   
import { AuthenticationService, CommonService } from './_services';
import { AuthenticationAdminService } from './admin/authentication.service';
import { User } from './_models';
import { ActivatedRoute, NavigationStart, RouterEvent,Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app', templateUrl: 'app.component.html'
})
export class AppComponent {
    currentUser: User;
	loading :boolean;
  subscription: Subscription;
  browserRefresh = false;
  pagename : boolean = true;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
		private commonService: CommonService,
    private _ActivatedRoute: ActivatedRoute
    ) { 

      this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.browserRefresh = !router.navigated;
        }
    });
       // this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }
	 ngOnInit() {
		 console.log('loading app component');
		  this.commonService.getLoader().subscribe(x => { 
		   setTimeout(() => {
              this.loading = x;
            }, 100);
		  
		  console.log('check loadre');
    });
    this.router.events.filter((event: RouterEvent) => event instanceof NavigationStart)
    .subscribe((x) => {
      if (x['url'].indexOf('admin/') > -1) {
          this.pagename = false;
      } else {
        this.pagename = true;
      }
      // console.log(this.pagename);
    });

    window.onbeforeunload = () => {
		console.log('window unload called');
     // if(!this.browserRefresh)
      //  this.logout();
    }
	
	window.onload = function () {
            console.log('window onload called');
};

    }

    logout() {
        this.authenticationService.logout('CUSTOMER');
      //  this.router.navigate(['/login']);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { URL } from '../../../urls'; 
import { User } from '../../../_models';  
import { AuthenticationService, CommonService } from '../../../_services';
declare var $ :any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.less']
})
export class ResetpasswordComponent implements OnInit {

securityID = '';
confirmPass = '';
verifySuccess = false;
	verifyFailed = false;
	successMsg = '';
objUser : User = new User();
  constructor(private activatedRoute : ActivatedRoute,private commonService: CommonService,private router: Router) { 

  }

  ngOnInit() {
	  	this.activatedRoute.params.subscribe((params: Params) => {
			this.securityID = params['id'].split('~')[0];
		  this.objUser.USER_ID = params['id'].split('~')[1];
		});
  }
  
  updatePassword(){
	 this.verifySuccess = false;
	 if(!this.ValidateForgotPwd()) return;
	 
	var url = URL.resetPassword;
	
	if(this.router.url.indexOf('resetadminpassword') > -1) url = URL.resetAdminPassword;
	
		var payload = this.objUser;
		payload.SECURITY_ID = this.securityID;
		this.commonService.setLoader(true);
		this.commonService.postData(url,payload).subscribe((data) => {
			this.commonService.setLoader(false);
			if(data.code == 200){	
        console.log("success : result : ", data); 
		if(data.message == 'success'){
			this.verifySuccess = true;
			this.commonService.showSuccess('Password updated successfully'); 
			if(this.router.url.indexOf('resetadminpassword') > -1)
				this.router.navigate(['/admin']);
			else
				this.router.navigate(['/login']);
		}else 
			this.commonService.showError('User ID not found.'); 
			}else this.commonService.showError("Error updating password.");
		
    }, err => {
      this.commonService.setLoader(false);
      this.commonService.showError("Error updating password.");
    })	
 }	 
 
 ValidateForgotPwd(){
		var retVal = true; 
		
		if(this.objUser.USER_ID.trim() == ''){ 
			this.commonService.showError('Enter User ID.');
			retVal = false;
		}
		if(this.objUser.PASSWORD.trim() == ''){ 
			this.commonService.showError('Enter Password.');
			retVal = false;
		}
		if(this.confirmPass.trim() == ''){ 
			this.commonService.showError('Enter Confirm Password.');
			retVal = false;
		}
		if(this.objUser.PASSWORD.trim() != this.confirmPass.trim()){ 
			this.commonService.showError('Entered and confirmed passwords do not match.');
			retVal = false;
		}
		 
		
		return retVal;
	}

}

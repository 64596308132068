
<!-- main app container -->
<div class="container" style="min-height: 75vh;">
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
    <router-outlet></router-outlet>
</div>
<div class="col-md-12" *ngIf="pagename">
<app-footer></app-footer>
</div>

<a href="javascript:void(0)" id="toTop"  *ngIf="!pagename" > </a>

<!-- credits -->

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL } from '../urls';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '../_models';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  currentUser: User;
  // private currentUserSubject: BehaviorSubject<User>;
  // public currentUser: Observable<User>;

  constructor(private router: Router, private http: HttpClient, private commonService: CommonService) {
    //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    //this.currentUser = this.currentUserSubject.asObservable();
  }

  getCurrentUser(): User {
    //this.currentUserSubject.value;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.currentUser;
  }


  setCurrentUser(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('authToken', user.token);
	  sessionStorage.setItem('loggedIn', 'true');
    //this.currentUserSubject.next(user);
  }

  isUserValid = false;
  async checkUserStatus() {
    var url = '';
    url = URL.getUserDetail;

    var payload = {
      USER_ID: this.currentUser.USER_ID
    }

    return new Promise((resolve, reject) => {

      this.commonService.postData(url, payload).subscribe((data) => {
        if (data.code == 200) {
          if (data.result.length > 0) {
            if (data.result[0].ACCOUNT_STATUS == 'APPROVED') {
              this.isUserValid = true;
            } else{
              this.isUserValid = false;
            }
            resolve('done');
          } else {
            this.isUserValid = false;//Deleted the account
            this.logout('CUSTOMER');
          }
        } else{
          this.isUserValid = false;
          resolve('done');
        }
      }, err => {
        this.isUserValid = false;
        reject(err);
      });
    });
  }



  logout(module: String) {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.clear();
    sessionStorage.setItem('loggedIn', 'false');
    this.navigateToLogin(module);
    // this.currentUserSubject.next(null);
  }

  navigateToLogin(module: String) {
    if (module == 'CUSTOMER')
      this.router.navigate(['login']);
    else
      this.router.navigate(['login']);
  }

   canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
	  
	  if(sessionStorage.getItem('loggedIn') != 'true'){
			this.logout('CUSTOMER');		  
		  return Observable.of(false);
	  }

    var user = this.getCurrentUser();
    if (user == undefined || user == null)
      this.navigateToLogin('CUSTOMER');
    else if (user.token == undefined || user.token == null)
      this.navigateToLogin('CUSTOMER');
    else
    return Observable.of(true);
     
  }

  async HasAccess() {
    var retVal = true 
    var user = this.getCurrentUser();
	
    if (user == undefined || user == null) retVal = false;
    else if (user.ACCESS_APPROVED == '0' || user.USER_EMAIL_VALID == '0') retVal = false;
    else if (user.CUSTOMER_ID == '') retVal = false;

    await this.checkUserStatus();
    if (!this.isUserValid){
      this.commonService.showError("Account disabled. For Document Portal account re-activation send email request to sales@gableseng.com.");
      this.navigateToLogin('CUSTOMER');
    }

    return retVal;
  }

  // canActivateAdmin(
  // route: ActivatedRouteSnapshot,
  // state: RouterStateSnapshot
  // ): Observable<boolean> { 

  // var user = this.getCurrentUser();
  // if (user== undefined || user == null) 
  // this.navigateToLogin('ADMIN');
  // else if (user.token== undefined || user.token == null) 
  // this.navigateToLogin('ADMIN');
  // else
  // return Observable.of(true);

  // }
}
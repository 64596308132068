export class Document {     
    DOC_CLASS: string= '';
	DOC_NAME: string = '';
	SOURCE: string = '';	
	APPROVER_ID: string = '';	
	CHECKED: boolean = false;
}

export class DocumentDetails {     
    DOC_CLASS: string= '';
	PART_NO: string = '';	
	DOC_NO: string = '';	
	DOC_REV: string = '';	
	DT_DOC_REV: string = '';	
	DOC_TITLE: string = '';	
	STATE: string = '';	
	FILE_STATE: string = '';	
	FILE_NAME: string = '';	
}
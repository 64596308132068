export class User {
    id: number =0;
	CUSTOMER_ID: string = '';
	ADD_CUSTOMER_ID: string = '';
	CUSTOMER_NAME: string = '';
	CUSTOMER_DISPALY: string='';
	USER_ID: string = '';
	EMAIL_ID: string = '';
	OLD_EMAIL_ID: string = '';
    USER_NAME: string = '';
    PASSWORD: string = '';
    FIRST_NAME: string = '';
	MIDDLE_NAME: string = '';
    LAST_NAME: string = '';
	token?: string = '';
	JOB_TITLE: string = '';
	COMPANY_NAME: string = '';
	COMPANY_ADDR: string = '';
	CITY: string = '';
	STATE : string = '';
	POSTAL_CODE: string = '';
	COUNTRY : string = '';
	CONTACT: string = '';
    authdata?: string;
	EXP_DATE: string = '';
	EXPIRATION_DATE:  string = '';
	USER_EMAIL_VALID: string = '';
	ACCESS_APPROVED: string = '';
	DOCUMENT_NOTIFICATION: number =0;
	SUBSCRIBE_NEWSLETTER: number =1;
	APPROVER_ID?: string = '';
	WRONG_LOGIN_ATTEMPT?: number =0;
	MESSAGE?: string = '';
	SUBJECT?: string = '';
	SECURITY_ID? : string = ''; 
	NOTES_TXT? : string = '';
	ACCOUNT_TYPE : string = 'Portal Only';
	ACCOUNT_STATUS: string = ''; 
	CUSTOMER_STATUS: Boolean= false; 
	UPDATED_DATE: string = ''; 
	UPDATED_BY: string = ''; 
	REVISION_SERVICE_STATUS: boolean = false;
	EDIT_CUSTOMER_DISPALY:any;
}

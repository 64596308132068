import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { CustomerHeaderComponent } from './components/customer-header/customer-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { VerifyemailidComponent } from './components/verifyemailid/verifyemailid.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';

@NgModule({
  declarations: [ CustomerHeaderComponent, FooterComponent, VerifyemailidComponent, ResetpasswordComponent],
  imports: [
    CommonModule  ,
	RouterModule,
	FormsModule
  ], 
  exports : [CustomerHeaderComponent, FooterComponent,VerifyemailidComponent]
})
export class SharedModule { }

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { URL } from '../../../urls'; 
import { AuthenticationService, CommonService } from '../../../_services';

@Component({
  selector: 'app-verifyemailid',
  templateUrl: './verifyemailid.component.html',
  styleUrls: ['./verifyemailid.component.less']
})
export class VerifyemailidComponent implements OnInit {

	verifySuccess = false;
	verifyFailed = false;
	successMsg = '';
	securityID = '';
	option = '';
  constructor(private activatedRoute : ActivatedRoute,private commonService: CommonService ) { 
 
	this.activatedRoute.params.subscribe((params: Params) => {
		  this.securityID = params['id'];
		});
		 console.log('securityID : ', this.securityID);
		this.VerifyMailID();
  }

  ngOnInit() {
	  
	   
  } 
  
  VerifyMailID(){
	  
	  var url = URL.VerifyEmailID;
	  this.successMsg = '';
		var payload = {
			security_id: this.securityID
		}
		this.commonService.setLoader(true);
		this.commonService.postData(url,payload).subscribe((data) => {
		if(data.code == 200){	
		this.commonService.setLoader(false); 
		this.verifySuccess = true;
        console.log("success : result : ", data);
		this.successMsg = data.data.message;
		this.option = data.data.option;
		}else  this.verifyFailed = true;
    }, err => {
		this.verifyFailed = true;
      this.commonService.setLoader(false); 
    })	
  }
}

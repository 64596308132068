import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
// import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers'; 
import { LoginComponent } from './customer/login';; 
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';; 
import { ToastrModule } from 'ng6-toastr-notifications';  
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule } from 'ngx-loading'; 

import { appRoutingModule } from './app-routing.module'; 

import { SignInComponent } from './admin/sign-in/sign-in.component';
import { PreventDblClickDirective } from './_directives/prevent-dbl-click.directive'

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,        
        NgxPaginationModule,
        Ng2SearchPipeModule,
		RouterModule,
		SharedModule,
		FormsModule,	 
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		NgxLoadingModule.forRoot({}), 
		appRoutingModule
    ],
    declarations: [
        AppComponent, 
        LoginComponent,
        SignInComponent,
        PreventDblClickDirective
    ],	
    providers: [
		//{ provide: ToastOptions, useClass: CustomOption }
        // { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        //fakeBackendProvider
    ], 
    bootstrap: [AppComponent]
})
export class AppModule { }
<div class="page_content">
	<div class="col-md-12 outer-box">
		<img src="/assets/gables-color-logo-400.png">

		<div *ngIf="verifySuccess" class="col-md-12 mesage-box success">
			<div class="col-md-12 message_section">
				<div class="col-md-2">
					<h1>Thank You</h1>
				</div>
				<div class="col-md-10 content">
					<p *ngIf="option === '1' || option === '3'">Your account will be reviewed by one of our team members for final approval.</p>
					<p *ngIf="option === '1' || option === '3'">You will receive a notification email once the approval process has been completed.</p>
					<p *ngIf="option === '2'">Your email address has been re-verified.</p>
					<div class="back_login">
						<a href="javascript:void(0)" class="text-link" routerLink='/login'>
						<Back to login </a>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="verifyFailed" class="col-md-12  mesage-box error">
			<h2>Error verifying Email ID. Please try again later.</h2>
		</div>
	</div>
</div>
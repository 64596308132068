import { environment } from '../environments/environment';

 var server = environment.apiUrl; 
server ="/gbe/api/";

export const URL = {
  SERVER: server,
  
  DocRepository : 'https://gables-documents.s3.ap-south-1.amazonaws.com/',
  //master data
  getCustomerInfo: server + 'getCustomerInfo/', 
  getCustomerByID: server + 'getCustomerByID/', 
  getAllDocClass: server + 'getAllDocClass/', 
  getAllParts: server + 'getAllParts/', 
  getMissingParts: server + 'getMissingParts/', 
  getCustomerParts: server + 'getCustomerParts/', 
  
  //login - user
  CUSTOMER_LOGIN: server + 'customerlogin/', 
  createAccount: server + 'createAccount/', 
  VerifyEmailID: server + 'VerifyEmailID/', 
  requestPasswordReset: server + 'requestPasswordReset/',   
  resetPassword: server + 'resetPassword/',   
  resetAdminPassword: server + 'resetAdminPassword/',   
  updateUserName: server + 'updateUserName/', 
  updateContactInfo: server + 'updateContactInfo/', 
  updateCommPref: server + 'updateCommPref/', 
  updateEMailID: server + 'updateEMailID/', 
  ResendVerificationLink: server + 'ResendVerificationLink/', 
  getUserDetail: server + 'getUserDetail/', 
  
  //menu
  getActiveParts: server + 'getActiveParts/', 
  getActiveParts_pub: server + 'getActiveParts_pub/', 
  getAllPartNos: server + 'getAllPartNos/', 
  getActiveDocuments: server + 'getActiveDocuments/',
	downloadFile: server + 'downloadFile/',  
  
  //search
  getPublicationList: server + 'getPublicationList/', 
  getSILList: server + 'getSILList/', 
  
  //customer profile 
  getPartsProfile: server + 'getPartsProfile/', 
  requestExpiryExtension: server + 'requestExpiryExtension/', 
  requestDocClasses: server + 'requestDocClasses/', 
  
  //parts list 
  getPartsList: server + 'getPartsList/', 
  getPartsListDets: server + 'getPartsListDets/', 
  
  //request parts
  getPartsClassDets: server + 'getPartsClassDets/', 
  requestParts: server + 'requestParts/', 
  addAuditLog: server + 'addAuditLog/', 
    
};

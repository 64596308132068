import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { ResponseContentType } from '@angular/http';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;

@Injectable({ providedIn: 'root' })
export class CommonService {
	//  authentication

	// headers: Headers;
	// options: RequestOptions;
	Loader: Subject<any> = new Subject();
	FilterOption: Subject<any> = new Subject();
	HeaderSearch: Subject<any> = new Subject();

	constructor(private http: HttpClient, public toastr: ToastrManager) {
		this.Loader = new Subject();
		this.FilterOption = new Subject();
		this.HeaderSearch = new Subject();

	}

	// getAuthorizationHeader(): any {
	// const headers = new Headers();
	// let userDetails = localStorage.getItem('authToken');
	// headers.append('Authorization', userDetails);

	// return headers;
	// }


	getData(url): Observable<any> {
		$('.toastr').hide();
		let userDetails = '';
		userDetails = localStorage.getItem('authToken');

		if (userDetails == undefined || userDetails == null) {
			return this.http.get(url).do(data => {
			});
		} else {
			let headers = new HttpHeaders({
				'Authorization': userDetails
			});
			let options = { headers: headers };
			return this.http.get(url, options).do(data => {
			});
		}
	}

	postData(url, data): Observable<any> {
		$('.toastr').hide();
		let userDetails = '';
		userDetails = localStorage.getItem('authToken');

		var jsonString = JSON.stringify(data);
		if (jsonString != undefined)
			jsonString = jsonString.replace("'", "''");
		data = JSON.parse(jsonString);

		if (userDetails == undefined || userDetails == null) {
			return this.http.post(url, data).do(data => {
			});
		} else {
			let headers = new HttpHeaders({
				'Authorization': userDetails
			});
			let options = { headers: headers };
			return this.http.post(url, data, options).do(data => {
			});
		}
	}

	promisify(url, payload) {
		let userDetails = '';
		userDetails = localStorage.getItem('authToken');

		if (userDetails == undefined || userDetails == null) {
			return this.http.post(url, payload).toPromise();
		} else {
			let headers = new HttpHeaders({
				'Authorization': userDetails
			});
			let options = { headers: headers };
			return this.http.post(url, payload, options).toPromise();
		}
	}

	setLoader(status) {
		this.Loader.next(status);
	}
	getLoader() {
		return this.Loader.asObservable();
	}

	setFilterOption(status) {
		console.log('setting filter option');
		this.FilterOption.next(status);
	}
	getFilterOption() {
		return this.FilterOption.asObservable();
	}

	setHeaderSearch(status) {
		this.HeaderSearch.next(status);
	}
	getHeaderSearch() {
		return this.HeaderSearch.asObservable();
	}

	downloadFile(url, data = null): Observable<Blob> {
		$('.toastr').hide();
		let userDetails = '';
		userDetails = localStorage.getItem('authToken');


		if (userDetails == undefined || userDetails == null) {
			return null;
		} else {
			let headers = new HttpHeaders({
				'Authorization': userDetails
			});
			let options = { headers: headers };
			return this.http.post(url, data, {...options, responseType: 'blob' }).do(data => {
			});
		}
	}

	downloadFile1_old(url) {
		var headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf');
		headers = headers.set('Access-Control-Allow-Origin', '*');
		headers.append('Access-Control-Allow-Credentials', 'true');
		headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS');
		headers.append('Access-Control-Allow-Headers', 'X-Requested-With, Content-Type, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding');

		return this.http.get(url, { headers: headers, responseType: 'blob' });

		// $('.toastr').hide();
		// let userDetails = '';
		// userDetails = localStorage.getItem('authToken'); 

		// if(userDetails == undefined || userDetails == null){
		// return this.http.get(url).do(data => {  
		// });
		// }else{
		// headers.append('Authorization',userDetails);
		// //let headers = new HttpHeaders({ 'Authorization': userDetails });
		// let options = { headers: headers };
		// return this.http.get(url, options).do(data => {  
		// });
		// }

	}


	isValuePresent(input) {

		if (input == null || input == undefined || input == "null" || input == "undefined")
			return false;
		else
			return true;
	}

	showSuccess(msg) {
		this.toastr.successToastr(msg, 'Success!');
	}

	//https://www.npmjs.com/package/ng6-toastr-notifications
	showError(msg, delay = false, useHtml: boolean = false, dismiss: boolean = false) {
		if (msg.indexOf('ResendVerificationLink') > -1)
		setTimeout(()=>{this.toastr.errorToastr(msg, 'Error', { dismiss: 'controlled',  showCloseButton: true, enableHTML: true });}, 600);
		else if (dismiss == true)
			this.toastr.errorToastr(msg, 'Error', { dismiss: 'auto',toastTimeout:3000, showCloseButton: true, enableHTML: useHtml });
		else
			this.toastr.errorToastr(msg, 'Error', { dismiss: 'click', showCloseButton: true, enableHTML: true });
	}

}

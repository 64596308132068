<div class="col-md-12 outer-box" >
    <img src="/assets/gables-color-logo-400.png" >
    
	<div class="card col-md-12">
    <div class="card col-md-6" style="margin: 0 auto; position: relative;">
        <h2 class="card-header primary-color">Welcome</h2>
		<p>To log in, enter your Email ID and password</p>
		<p>If you are new to this site or have been redirected from our old Documentation portal, please use the New Account link below to create a new account and password.</p>
		<p><abbr class="required" >*</abbr>Indicates required field</p>
        <div class="card-body"> 
                <div class="form-group">
                    <label for="username" class="control-label">Email ID 
					<abbr class="required" >*</abbr></label>
                    <input type="text" [(ngModel)]="objUser.USER_ID" name="username" class="form-control"  (keyup)="checkKey($event)" /> 
                </div>
                <div class="form-group">
                    <label for="password" class="control-label">Password 
					<abbr class="required" >*</abbr></label>
                    <input type="password" name="password" class="form-control" [(ngModel)]="objUser.PASSWORD"  (keyup)="checkKey($event)" /> 
                </div>
                <button type="button" class="btn btn-primary primary-bg" (click)="onSubmit()"> 
                    Login
                </button>
              
			<div class="login-links">			  
				 <a href="javascript:void(0)" (click)="createAccount()" class="primary-color">New Account</a> | <a href="javascript:void(0)" (click)="forgotPassword()" class="primary-color">Forgot Password</a> 
				 <!-- | <a href="javascript:void(0)" class="primary-color">Help</a> -->
			</div>
            <!-- </form> -->
        </div>
    </div>
	</div>
</div>

 

<div class="modal" id="createAccount" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
		<h4 class="modal-title">Registration Form</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>          
        </div>
        <div class="modal-body">	
		<!-- <div class="col-md-6">
			  <label class="control-label " for="new_user_id">User ID</label>
			  <div class="col-md-10">
				<input type="textbox" name="new_user_id" class="form-control" [(ngModel)]="objUser.USER_ID"   /> 
			</div>
		</div> -->
		<div class="col-md-6">
			<label class="control-label required" for="new_user_name">First Name</label>
			<div class="col-md-10">
			  <input type="textbox" name="firstName" class="form-control" [(ngModel)]="objUser.FIRST_NAME"   /> 
		  </div>
	   </div>
	   <div class="col-md-6">
			<label class="control-label required" for="new_user_name">Last Name</label>
			<div class="col-md-10">
		  		<input type="textbox" name="lastName" class="form-control" [(ngModel)]="objUser.LAST_NAME"   /> 
	  		</div>
  		</div>
		<div class="col-md-6">
			  <label class="control-label required" for="new_email_id">Email Address <!--<i>(Corporate email only)</i>--></label>
			  <div class="col-md-10">
				<input type="textbox" name="new_email_id" class="form-control" [(ngModel)]="objUser.EMAIL_ID"   /> 
			</div>
		</div>
		<div class="col-md-6">
			<label class="control-label required" for="new_user_name">Phone</label>
			<div class="col-md-10">
				<input type="textbox" name="contact" class="form-control" [(ngModel)]="objUser.CONTACT"   /> 
		  	</div>
	  	</div>
		<div class="col-md-6">
			  <label class="control-label required" for="new_pass">Password</label>
			  <div class="col-md-10">
				<input type="password" name="new_pass" class="form-control" [(ngModel)]="objUser.PASSWORD"   /> 
			</div>
		</div>
		<div class="col-md-6">
			  <label class="control-label required" for="new_confirm_pass">Confirm Password</label>
			  <div class="col-md-10">
				<input type="password" name="new_confirm_pass" class="form-control" [(ngModel)]="confirmPass"   /> 
			</div>
		</div>		
		<div class="col-md-6">
			<label class="control-label required" for="new_user_name">Company Name</label>
			<div class="col-md-10">
			  	<input type="textbox" name="company_name" class="form-control" [(ngModel)]="objUser.COMPANY_NAME"   /> 
		  	</div>
	  	</div>
	    <div class="col-md-6">
			<label class="control-label required" for="new_user_name">Company Address</label>
			<div class="col-md-10">
			  <input type="textbox" name="company_addr" class="form-control" [(ngModel)]="objUser.COMPANY_ADDR"   /> 
		  	</div>
		</div>
		<div class="col-md-6">
			<label class="control-label required" for="new_user_name">Job Title</label>
			<div class="col-md-10">
				<input type="textbox" name="job_title" class="form-control" [(ngModel)]="objUser.JOB_TITLE"   /> 
			</div>
		</div>
		<div class="col-md-6">
			<label class="col-md-12 control-label required" for="new_user_name">City</label>
			<div class="col-md-10">
			  <input type="textbox" name="city" class="form-control" [(ngModel)]="objUser.CITY"   /> 
		  </div>
		</div>
		<div class="col-md-6">
			<label class="col-md-12 control-label required" for="new_user_name">State</label>
			<div class="col-md-10">
				<input type="textbox" name="state" class="form-control" [(ngModel)]="objUser.STATE"   /> 
			</div>
	  	</div>
	  	<div class="col-md-6">
			<label class="control-label required" for="new_user_name">Country</label>
		  	<div class="col-md-10">
				<input type="textbox" name="country" class="form-control" [(ngModel)]="objUser.COUNTRY"   /> 
		  	</div>
	  	</div>
	  	<div class="col-md-6">
			<label class="control-label required" for="new_user_name">Postal Code</label>
			<div class="col-md-10">
				<input type="textbox" name="postal_code" class="form-control" [(ngModel)]="objUser.POSTAL_CODE"   /> 
	  		</div>
		</div>
		<div class="col-md-6">
			<label class="control-label" for="new_user_name">* Indicates Mandatory Field</label>
		</div>
		<!--  
		<div class="col-md-6">
				<label class="control-label required" for="new_user_name">Middle Initial</label>
				<div class="col-md-10">
					<input type="textbox" name="middleName" class="form-control" [(ngModel)]="objUser.MIDDLE_NAME"   /> 
				</div>
		</div>
		-->
		
		<div class="col-md-12">
			<label class="control-label" for="new_user_name" style="padding-top:10px">Terms of Use</label> 
				<div class="terms">
					<p> GENERAL<br>
						This website is provided by Gables Engineering, Inc. (Gables), as an on-line service subject to your acceptance of the terms and conditions set forth in this agreement (“Agreement”). 
						This website and information and materials presented on it (collectively “Site”) are Gables property. Please read this Agreement carefully before accessing, browsing or using this Site. By accessing or using the Site, you agree that you have read, understood and have become legally bound by the following terms and conditions. If you do not agree to these terms and conditions do not access or use this Site.
					</p>
					<p>
						COPYRIGHT<br>
						All content included on this Site, such as HTML code, scripts, text, graphics, logos, button icons, images, video, software created to provide mobile applications and audio (collectively, “Materials”), is our property and protected by United States and international copyright laws. 
						All software used on this site is our property or the property of our service provider or its suppliers and protected by United States and international copyright laws.
					</p>
					<p>
						LIMITED LICENSE<br>
						Subject to the terms and conditions set forth herein and all applicable local laws and regulations, Gables grants you a non-exclusive, non-transferable, personal, limited license to access, use this Site and the materials contained herein. 
						This license is not a transfer of title to the Site or materials contained herein and is expressly subject to the following restrictions: (1) you must retain all copyright and other proprietary notices on all copies of the Site; and (2) you may not modify the Site or any materials in any way, reproduce, publicly display, perform, distribute, create derivative works of, or otherwise use the Site and any materials for any public or commercial purpose, except as otherwise specifically permitted herein.  
						You may not use the Site or any materials to design or reverse engineer, manufacture parts, or obtain FAA or other government approval without Gables’ express, written permission.	
					</p>
					<p>
						Any unauthorized use terminates your limited license, and we may revoke this limited license at any time for any or no reason.  
						In the event of a violation of these Terms, Gables reserves the right to seek all remedies available by law and in equity.  
						We retain the right at our sole discretion to deny access to anyone to this Site, at any time and for any or no reason, including, but not limited to, for violation of these Terms.  
						You are also advised that Gables will aggressively enforce its intellectual property rights to the fullest extent of the law, including the seeking of civil remedies and criminal prosecution.
					</p>
					<p>
						DISCLAIMER<br>
						Gables provides this Site and its contents on an “as is” basis and makes no representations or warranties of any kind with respect to this Site or its contents. 
						Gables disclaims all such representations and warranties, whether express or implied, including, but not limited to, the warranties of merchantability and fitness for a particular purpose. 
						In addition, Gables does not represent or warrant that the information accessible via this Site is accurate, complete or current.
					</p>
					<p>
						CHANGES<br>
						All content contained herein is subject to change without notice. 
						Gables reserves the right to change or modify the terms and conditions applicable to the use of this Site at any time. 
						Such changes, modifications, additions, or deletions to the terms and conditions of use shall be effective immediately upon notice thereof, which may be given by any means including, but not limited to, posting new terms and conditions on the Site. 
						Any use of the Site after such change or modification shall be deemed to constitute acceptance by you of such changes, modifications, additions, or deletions. Gables may terminate, change, suspend or discontinue any aspect of the Site, including the availability of any features of the Site, at any time. 
						Gables may also impose limits on certain features and services or restrict your access to parts of the Site without notice or liability.
					</p>
					<p>
						LIMITATION OF LIABILITY<br>
						Neither Gables nor any of its subsidiaries, employees, or other representatives shall be liable for damages arising out of or in connection with the use of this Site or any linked site. 
						Under no circumstances shall Gables have any liability for any consequential, incidental, indirect, special, or punitive damages or costs, including, but not limited to, lost profits, business interruption, loss of information or data, or loss of goodwill, loss of or damage to property, and any claims of third parties, arising out of or in connection with the use, copying, or display of this Site or its contents, regardless of whether Gables has been advised, knew, or should have known of the possibility thereof.
					</p>
					<p>
						INTERNATIONAL USERS AND JURISDICTION<br>
						This Site is maintained and controlled by Gables in the United States of America. 
						Gables makes no representation that materials on this Site are appropriate or available for use at other locations outside of the United States and access to this Site from territories where this Site’s contents are illegal is prohibited. 
						If you access this Site from locations outside the United States, you are responsible for compliance with all local laws. 
						The laws of the State of Florida shall govern the content and use of this Site, without giving effect to any of the conflict of law principles or rules thereof. 
						Information contained in this website may be subject to U.S. government export controls. This information is subject to all laws, regulations and administrative acts, now or hereafter in effect of the U.S. government regarding the exportation and/or re-exportation of information. 
						The recipient of this information acknowledges that they will be responsible for compliance as necessary with such laws, regulations and administrative acts. Prior to the disclosure of information presented on this website to any foreign national, the recipient will obtain any such approvals in a manner consistent with the terms and conditions of this Agreement.
					</p>
				</div>
		</div>
		<div class="col-md-12">
			<input type="checkbox" name="agree" [(ngModel)]="agree" /> 
			<label class="control-label" for="new_user_name">I agree with the Terms of Use.</label>
		</div>
		<div class="col-md-12">
			<input type="checkbox" name="agree" [(ngModel)]="objUser.DOCUMENT_NOTIFICATION" /> 
			<label class="control-label" for="new_user_name" style="width:98%">Receive document revision notifications for subscribed parts. Subscribed parts can be viewed from the My Parts menu.</label>
		</div>
		 <!-- <div class="col-md-4"> -->
			  <!-- <label class="control-label " for="new_customer">Customer</label> -->
			  <!-- <div class="col-md-10"> -->
				<!-- <select  name="new_customer"  class="form-control" -->
					<!-- [(ngModel)]="objUser.CUSTOMER_ID" > -->
					<!-- <option *ngFor="let customer of customerList" [value]='customer.CUSTOMER_ID'>{{customer.CUSTOMER_NAME}} -->
					<!-- </option> -->
			 <!-- </select> -->
			<!-- </div> -->
		<!-- </div> -->
        </div>
        <div class="modal-footer">
		 <button type="button" class="btn btn-primary  primary-bg"(click)="submitNewAccount()" >Submit</button>
          <button type="button" class="btn btn-primary  primary-bg" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>
  
  
<div class="modal" id="resetPassword" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
		<h4 class="modal-title">Reset Password</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>          
        </div>
        <div class="modal-body">  
		<!-- </div> -->
          <div class="col-md-10">
			  <label class="control-label col-md-10" for="new_user_id">Email ID</label>
			  <div class="col-md-10">
				<input type="textbox" name="new_user_id" class="form-control" [(ngModel)]="objUser.USER_ID"   /> 
			</div>
		</div> 
		<!-- <div class="col-md-4"> -->
			  <!-- <label class="control-label " for="new_pass">Password</label> -->
			  <!-- <div class="col-md-10"> -->
				<!-- <input type="password" name="new_pass" class="form-control" [(ngModel)]="objUser.PASSWORD"   />  -->
			<!-- </div> -->
		<!-- </div> -->
		<!-- <div class="col-md-4"> -->
			  <!-- <label class="control-label " for="new_confirm_pass">Confirm Password</label> -->
			  <!-- <div class="col-md-10"> -->
				<!-- <input type="password" name="new_confirm_pass" class="form-control" [(ngModel)]="confirmPass"   />  -->
			<!-- </div> -->
		<!-- </div> -->
		
        </div>
        <div class="modal-footer">
		 <button type="button" class="btn btn-primary  primary-bg"(click)="resetPassword()" >Submit</button>
          <button type="button" class="btn btn-primary  primary-bg" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>  
 

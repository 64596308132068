import { Component, OnInit, ViewContainerRef, Injectable, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { URL } from '../../urls';
import { AuthenticationService, CommonService } from '../../_services';
import { User, Customer } from '../../_models';
declare var $: any;
import * as moment from 'moment';

@Component({
	templateUrl: 'login.component.html',
	styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	submitted = false;
	returnUrl: string;
	error = '';
	objUser: User = new User();
	customerList: Customer[] = [];
	confirmPass = '';
	termsConditions = '';
	agree = false;
	private subscriptions: { [key: string]: Function[]; } = {};
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private commonService: CommonService,
		private zone: NgZone
	) {
		this.objUser = new User();

		this.subscriptions['ResendVerificationLink'] = [
			function (input) {
				console.log(input);
				this.ResendVerificationLink(this)
			}
		];
		this.subscriptions['ForgotPassword'] = [function (): void {
			$('#createAccount').modal('hide');
			this.forgotPassword()
		}];
		window['fireAngularEvent'] = (eventName, args) => {
			if (!this.subscriptions[eventName]) {
				throw new Error('Event has to be defined in the event list.')
			}

			zone.run(() => {
				this.fireEvent(eventName, this);
			});
		};

		window['subscribeToAngularEvent'] = (eventName, fn) => {
			this.subscribe(eventName, fn);
		};
		//this.toastr.setRootViewContainerRef(vcr);
		// redirect to home if already logged in
		// if (this.authenticationService.currentUserValue) { 
		// this.router.navigate(['/']);
		// }
	}

	subscribe(eventName: string, fn: Function) {
		if (!this.subscriptions[eventName]) {
			throw new Error('Event has to be defined in the event list.');
		}

		this.subscriptions[eventName].push(fn);
	}

	fireEvent(eventName: string, args) {
		if (!this.subscriptions[eventName]) {
			throw new Error('Event has to be defined in the event list.');
		}

		this.subscriptions[eventName].forEach((fn) => {
			fn.apply(args, args);
		});
	}

	ngOnInit() {
		//localStorage.clear();
		localStorage.removeItem('home');
	}






	ValidateData() {
		var retVal = true;
		$('.toastr').hide();

		if (this.objUser.USER_ID.trim() == '') {
			this.commonService.showError('Enter Email ID');
			retVal = false;
		}
		if (this.objUser.PASSWORD.trim() == '') {
			this.commonService.showError('Enter Password');
			retVal = false;
		}

		return retVal;
	}
	onSubmit() {

		if (!this.ValidateData()) return;

		var url = URL.CUSTOMER_LOGIN;
		var payload = this.objUser;
		this.commonService.setLoader(true);
		this.commonService.postData(url, payload).subscribe((data) => {
			this.commonService.setLoader(false);

			if (data.code == 200) {
				console.log("success : result : ", data);

				if (data.message == 'success') {
					this.objUser = data.data[0];

					var a = moment().format('MM/DD/YYYY');
					var b = moment(this.objUser.EXP_DATE, 'MM/DD/YYYY');
					var diffDays = b.diff(a, 'days');
					this.authenticationService.setCurrentUser(this.objUser);
					// if(diffDays < 0){
					// 	// if(this.objUser.USER_EMAIL_VALID == '0' && this.objUser.ACCESS_APPROVED == '0')
					// 	if(this.objUser.ACCOUNT_STATUS.toUpperCase() == 'PENDING' || this.objUser.ACCOUNT_STATUS.toUpperCase() == 'DISABLED')
					// 		// this.commonService.showError("Account disabled. If you believe this is in error, please contact customer support at 305-774-4400.");
					// 		this.commonService.showError("Account disabled. For Document Portal account re-activation send email request to sales@gableseng.com.");
					// 		else if(this.objUser.ACCOUNT_STATUS.toUpperCase() == 'EXPIRED')
					// 		// // this.commonService.showError("Account Expired.");
					// 		this.commonService.showError("Account expired. Access temporarily disabled pending email verification. Select <a href='javascript:void(0)' onclick='window.fireAngularEvent(\"ResendVerificationLink\", null)'><b>VERIFY</b></a> to send a verification email to your registered email address. Follow instructions in email to verify your account.");	

					// 	 // else if(this.objUser.USER_EMAIL_VALID == '0' && this.objUser.ACCESS_APPROVED == '1')

					// }else{
					//expired
					// if(this.objUser.USER_EMAIL_VALID == '0' && this.objUser.ACCESS_APPROVED == '0')
					if (this.objUser.ACCOUNT_STATUS.toUpperCase() == 'PENDING')
						// this.commonService.showError("Account pending email verification. An email was previously sent to you for verification of your email address. Please check your inbox for more details.", true);
						this.commonService.showError("Account pending email verification. Select <a style='color:yellow' href='javascript:void(0)' onclick='window.fireAngularEvent(\"ResendVerificationLink\", null)'><b><u>VERIFY</u></b></a> to resend a verification email to your registered email address. Follow instructions in email to verify your account.", true);
					// else if(this.objUser.USER_EMAIL_VALID == '1' && this.objUser.ACCESS_APPROVED == '0'){
					else if (this.objUser.ACCOUNT_STATUS.toUpperCase() == 'WAITING_APPROVAL') {
						// this.commonService.showError("Login successful. Thank you. Your access to site is pending approval. Please try again later.");
						this.commonService.showError("Your access to site is waiting approval. Please try again later.");
						// this.authenticationService.setCurrentUser(this.objUser);  
						// }else if(this.objUser.USER_EMAIL_VALID == '1' && this.objUser.CUSTOMER_ID == ''){
					} else if (this.objUser.ACCOUNT_STATUS.toUpperCase() == 'APPROVED') {
						// this.commonService.showError("Login successful. You do not have customer assigned to you. Please contact administrator.");
						this.commonService.showSuccess("Login successful.");

						this.showWarnings();
						
						this.authenticationService.setCurrentUser(this.objUser);
						this.router.navigate(['/customer/home']);
					} else if (this.objUser.ACCOUNT_STATUS.toUpperCase() == 'EXPIRED') {
						// // this.commonService.showError("Account Expired.");
						this.commonService.showError("Account expired. Access temporarily disabled pending email verification. Select <a style='color:yellow' href='javascript:void(0)' onclick='window.fireAngularEvent(\"ResendVerificationLink\", null)'><b><u><u>VERIFY</u></u></b></a> to send a verification email to your registered email address. Follow instructions in email to verify your account.");
					} else if (this.objUser.ACCOUNT_STATUS.toUpperCase() == 'DISABLED')
						// // this.commonService.showError("Account Expired.");
						this.commonService.showError("Account disabled. For Document Portal account re-activation send email request to sales@gableseng.com.");
					else {

						this.showWarnings();

						//all OK, proceed
						this.authenticationService.setCurrentUser(this.objUser);
						// this.commonService.showSuccess('Login Successful');
						this.commonService.showSuccess('Login successful.');
						this.router.navigate(['/customer/home']);
					}
					//}
				} else {
					this.commonService.showError(data.message);
				}
			} else {
				this.commonService.showError('Error logging in to the system.');
			}


		}, err => {
			this.commonService.setLoader(false);
			this.commonService.showError("Error logging in.");
		})

		//this.submitted = true; 
	}

	showWarnings() {
		if (this.objUser.USER_EMAIL_VALID == '0')
			this.commonService.showError("Account pending email verification. Select <a style='color:yellow' href='javascript:void(0)' onclick='window.fireAngularEvent(\"ResendVerificationLink\", null)'><b><u>VERIFY</u></b></a> to resend a verification email to your registered email address. Follow instructions in email to verify your account.", true);

		if (this.objUser.CUSTOMER_ID == '')
			this.commonService.showError("You do not have customer assigned to you. Please contact administrator.");
	}


	createAccount() {
		this.objUser = new User();
		this.confirmPass = '';
		this.agree = false;
		this.objUser.DOCUMENT_NOTIFICATION = 1;
		$('#createAccount').modal('show');
	}

	ValidateNewAccount() {
		var retVal = true;
		$('.toastr').hide();

		// if(this.objUser.CUSTOMER_ID.trim() == ''){ 
		// this.commonService.showError('Select Customer.');
		// retVal = false;
		// }
		if (this.objUser.FIRST_NAME.trim() == '') {
			this.commonService.showError('Enter first name.');
			retVal = false;
		}

		if (this.objUser.LAST_NAME.trim() == '') {
			this.commonService.showError('Enter last name.');
			retVal = false;
		}

		if (this.objUser.EMAIL_ID.trim() == '') {
			this.commonService.showError('Enter Email ID.');
			retVal = false;
		}

		if (this.objUser.CONTACT.trim() == '') {
			this.commonService.showError('Enter phone.');
			retVal = false;
		}

		// if(this.objUser.USER_ID.trim() == ''){ 
		// 	this.commonService.showError('Enter User ID.');
		// 	retVal = false;
		// }
		if (this.objUser.PASSWORD.trim() == '') {
			this.commonService.showError('Enter Password.');
			retVal = false;
		}
		if (this.confirmPass.trim() == '') {
			this.commonService.showError('Enter Confirm Password.');
			retVal = false;
		}


		if (this.objUser.PASSWORD.trim() != this.confirmPass.trim()) {
			this.commonService.showError('Entered and confirmed passwords do not match.');
			retVal = false;
		}

		if (this.objUser.COMPANY_NAME.trim() == '') {
			this.commonService.showError('Enter company name.');
			retVal = false;
		}

		if (this.objUser.COMPANY_ADDR.trim() == '') {
			this.commonService.showError('Enter company address.');
			retVal = false;
		}

		if (this.objUser.JOB_TITLE.trim() == '') {
			this.commonService.showError('Enter job title.');
			retVal = false;
		}

		if (this.objUser.CITY.trim() == '') {
			this.commonService.showError('Enter city.');
			retVal = false;
		}

		if (this.objUser.STATE.trim() == '') {
			this.commonService.showError('Enter state.');
			retVal = false;
		}

		if (this.objUser.COUNTRY.trim() == '') {
			this.commonService.showError('Enter country.');
			retVal = false;
		}

		if (this.objUser.POSTAL_CODE.trim() == '') {
			this.commonService.showError('Enter postal code.');
			retVal = false;
		}

		if (!this.agree) {
			this.commonService.showError('Please agree to the terms and conditions.');
			retVal = false;
		}

		var mailID = this.objUser.EMAIL_ID.trim();

		// var freelist = ["gmail.com","yahoo.com","hotmail.com","yahoo.co.in","aol.com","abc.com","xyz.com","pqr.com","rediffmail.com","live.com","outlook.com","me.com","msn.com","ymail.com"]; 
		// for(var i=0; i< freelist.length;i++){
		// 	if(mailID.endsWith(freelist[i])){
		// 		this.commonService.showError('Only corporate email id is accepted to register new account.');
		// 	retVal = false;
		// 		break;
		// 	}
		// }

		if (this.objUser.EMAIL_ID) {
			var email = this.objUser.EMAIL_ID;
			var reg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
			if (!reg.test(email)) {
				this.commonService.showError('Enter Valid Email Address.');
				retVal = false;
			}
		}


		return retVal;
	}

	submitNewAccount() {

		if (!this.ValidateNewAccount()) return;

		var url = URL.createAccount;
		this.objUser.USER_ID = this.objUser.EMAIL_ID;
		var payload = this.objUser;
		this.commonService.setLoader(true);
		this.commonService.postData(url, payload).subscribe((data) => {
			this.commonService.setLoader(false);
			if (data.code == 200) {
				console.log("success : result : ", data);
				this.commonService.showSuccess('An email has been sent to you for verification to your email address. Please check your inbox for more details.');
				$('#createAccount').modal('hide');
			} else {

				if (data.error != undefined && data.error.message.includes('Violation of PRIMARY KEY constraint'))
					this.commonService.showError("That Email Address is already registered. Please use the <a class='primary-color' onclick='window.fireAngularEvent(\"ForgotPassword\")'>Forgot Password link</a> to reset your password and regain access to your account", undefined, true);
				else
					this.commonService.showError("Error creating account.");
			}
		}, err => {
			this.commonService.setLoader(false);
			this.commonService.showError("Error creating account.");
		})

	}

	forgotPassword() {
		this.objUser = new User();
		this.confirmPass = '';
		$('#resetPassword').modal('show');
	}

	resetPassword() {
		$('.toastr').hide();

		if (this.objUser.USER_ID.trim() == '') {
			this.commonService.showError('Enter Email ID.');
			return;
		}

		var url = URL.requestPasswordReset;
		var payload = this.objUser;
		this.commonService.setLoader(true);
		this.commonService.postData(url, payload).subscribe((data) => {
			this.commonService.setLoader(false);
			if (data.code == 200) {
				console.log("success : result : ", data);
				if (data.message == 'success') {
					this.commonService.showSuccess('Password reset link  sent to your email address. Please check your inbox for more details. ');
					$('#resetPassword').modal('hide');
				} else
					this.commonService.showError('Email ID not found.');
			} else this.commonService.showError("Error updating password.");

		}, err => {
			this.commonService.setLoader(false);
			this.commonService.showError("Error updating password.");
		})
	}

	updatePassword() {

		$('.toastr').hide();
		if (!this.ValidateForgotPwd()) return;

		var url = URL.resetPassword;

		this.objUser.USER_ID = this.objUser.EMAIL_ID;
		var payload = this.objUser;
		this.commonService.setLoader(true);
		this.commonService.postData(url, payload).subscribe((data) => {
			this.commonService.setLoader(false);
			if (data.code == 200) {
				console.log("success : result : ", data);
				if (data.message == 'success') {
					this.commonService.showSuccess('Password updated successfully');
					$('#resetPassword').modal('hide');
				} else
					this.commonService.showError('Email ID not found.');
			} else this.commonService.showError("Error updating password.");

		}, err => {
			this.commonService.setLoader(false);
			this.commonService.showError("Error updating password.");
		})
	}

	ValidateForgotPwd() {
		var retVal = true;

		if (this.objUser.USER_ID.trim() == '') {
			this.commonService.showError('Enter Email ID.');
			retVal = false;
		}
		if (this.objUser.PASSWORD.trim() == '') {
			this.commonService.showError('Enter Password.');
			retVal = false;
		}
		if (this.confirmPass.trim() == '') {
			this.commonService.showError('Enter Confirm Password.');
			retVal = false;
		}
		if (this.objUser.PASSWORD.trim() != this.confirmPass.trim()) {
			this.commonService.showError('Entered and confirmed passwords do not match.');
			retVal = false;
		}


		return retVal;
	}

	checkKey(event) {
		if (event.keyCode == 13) {
			this.onSubmit();
		}
	}

	ResendVerificationLink(thisComp) {

		var url = URL.ResendVerificationLink;
		thisComp.commonService.setLoader(true);
		var payload = {
			USER_ID: thisComp.objUser.USER_ID
		}
		thisComp.commonService.postData(url, payload).subscribe((data) => {
			console.log("success : result : ", data);

			if (data.code == 200) {
				thisComp.commonService.showSuccess('Verification email sent successfully');
			} else
				thisComp.commonService.showError('Error sending mail');
			thisComp.commonService.setLoader(false);
		}, err => {
			thisComp.commonService.setLoader(false);
			thisComp.commonService.showError('Error sending mail');
		})

	}

}

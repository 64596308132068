import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { URL } from '../../adminurls';
import { CommonService, PartService } from '../../_services';  
import { AuthenticationAdminService } from '../authentication.service';  
import { User,Customer } from '../../_models';  
declare var $ :any;
import * as moment from 'moment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.less']
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup; 
  submitted = false;
  returnUrl: string;
  error = '';
  objUser : User = new User();
  customerList : Customer[] = [];
  confirmPass = '';
  termsConditions = '';
  agree= false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationAdminService,
    private commonService: CommonService,
    private partService: PartService

  ) { }

  ngOnInit() {
	//  localStorage.clear();
  }
  
	initMasterData(){
		this.termsConditions  = `Terms and conditions: 
Introduction. These Website Standard Terms And Conditions (these "Terms" or these "Website Standard Terms And Conditions") contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this "Website"). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions. This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor. 
`;
		// var url = URL.getCustomerInfo;
		// this.commonService.setLoader(true);
		// this.commonService.postData(url,null).subscribe((data) => {
	    // this.commonService.setLoader(false);
        // console.log("success : result : ", data); 
		// this.customerList = data.result;
    // }, err => {
      // this.commonService.setLoader(false);
      // this.commonService.showError("Error getting customers" );
    // })
	}
	ValidateData(){
		var retVal = true;
		
		if(this.objUser.USER_ID.trim() == ''){ 
			this.commonService.showError('Enter Email ID');
			retVal = false;
		}
		if(this.objUser.PASSWORD.trim() == ''){ 
			this.commonService.showError('Enter Password');
			retVal = false;
		}
		
		return retVal;
  }
  
  onSubmit() {
		            console.log('admin login');
    // this.router.navigate(['/admin/user-details']);
		if(!this.ValidateData()) return;
		
    var url = URL.admin_login_auth;
    var payload = this.objUser;
    this.commonService.setLoader(true);
    let promise = new Promise((resolve, reject) => {		  
		  let promiseArray = [];
		  promiseArray.push(this.partService.postMethodData(url,payload)); 
		  Promise.all(promiseArray).then(result => {
        console.log(result);
        if(result.length > 0){
				  if(result[0].code == 200){
            if(result[0].message == "success"){
              // $('#createAccount').modal('hide');
              this.commonService.setLoader(false);
              this.authenticationService.setCurrentUser(result[0].data[0]); 
              this.commonService.showSuccess('Successfully Connected');              
              this.router.navigate(['/admin/customer-profile-requests']);
            } else {
              // $('#createAccount').modal('hide');
              this.commonService.setLoader(false);
              // this.authenticationService.setCurrentUser(result[0].data[0]); 
              this.commonService.showError(result[0].message);              
              // this.router.navigate(['/user-details']);  
            }
				  }else 
				  if(result[0].code == 400){
            this.commonService.setLoader(false);
            this.commonService.showError('Input Validation failed.'); 
          } else {
          this.commonService.setLoader(false);
				  this.commonService.showError('Error connecting to server'); 
          } 
				}
				resolve('done');

      }).catch(err => {
          console.log(err);
          this.commonService.setLoader(false);
          this.commonService.showError('Error connecting to server');
          reject(err);
          }) 
    }); 
  }
   /** forgot password */
   
 forgotPassword(){
  this.objUser = new User();
  this.confirmPass = ''; 
  $('#resetPassword').modal('show');
}	

updatePassword(){
  
  if(!this.ValidateForgotPwd()) return;
  
 var url = URL.forgot_password;
 var payload = this.objUser;
 this.commonService.setLoader(true);
 let promise = new Promise((resolve, reject) => {		  
   let promiseArray = [];
   promiseArray.push(this.partService.postMethodData(url,payload)); 
   Promise.all(promiseArray).then(result => {
     console.log(result);
     if(result.length > 0){
       if(result[0].code == 200){
         if(result[0].message == "success"){
          $('#resetPassword').modal('hide');
           this.commonService.setLoader(false);
           this.commonService.showSuccess('Password updated successfully');             
         } else {
          this.commonService.setLoader(false);
          this.commonService.showError('Email ID not found.');  
         }
       }else {
       this.commonService.setLoader(false);
       this.commonService.showError('Error updating password.'); 
       } 
     }
     resolve('done');

   }).catch(err => {
       console.log(err);
       this.commonService.setLoader(false);
       this.commonService.showError('Error updating password.');
       reject(err);
       }) 
 }); 
}	 

ValidateForgotPwd(){
   var retVal = true; 
   
   if(this.objUser.USER_ID.trim() == ''){ 
     this.commonService.showError('Enter Email ID.');
     retVal = false;
   }
   if(this.objUser.PASSWORD.trim() == ''){ 
     this.commonService.showError('Enter Password.');
     retVal = false;
   }
   if(this.confirmPass.trim() == ''){ 
     this.commonService.showError('Enter Confirm Password.');
     retVal = false;
   }
   if(this.objUser.PASSWORD.trim() != this.confirmPass.trim()){ 
     this.commonService.showError('Entered and confirmed passwords do not match.');
     retVal = false;
   }
    
   
   return retVal;
 }
	/** Create New Account */
  createAccount(){
    this.objUser = new User();
    this.confirmPass = '';
    this.agree = false;
    this.initMasterData();
    $('#createAccount').modal('show');
  }	 
  
  ValidateNewAccount(){
    var retVal = true;
    
    // if(this.objUser.CUSTOMER_ID.trim() == ''){ 
      // this.commonService.showError('Select Customer.');
      // retVal = false;
    // }
    if(this.objUser.EMAIL_ID.trim() == ''){ 
      this.commonService.showError('Enter Email ID.');
      retVal = false;
    }
    
    if(this.objUser.USER_ID.trim() == ''){ 
      this.commonService.showError('Enter Email ID.');
      retVal = false;
    }
    if(this.objUser.PASSWORD.trim() == ''){ 
      this.commonService.showError('Enter Password.');
      retVal = false;
    }
    if(this.confirmPass.trim() == ''){ 
      this.commonService.showError('Enter Confirm Password.');
      retVal = false;
    }
    if(this.objUser.PASSWORD.trim() != this.confirmPass.trim()){ 
      this.commonService.showError('Entered and confirmed passwords do not match.');
      retVal = false;
    }
    
    if(!this.agree){
      this.commonService.showError('Please agree to the terms and conditions.');
      retVal = false;
    }
    
    return retVal;
  }

  submitNewAccount(){
  
  if(!this.ValidateNewAccount()) return;
    
    var url = URL.create_account;
    var payload = this.objUser
    let promise = new Promise((resolve, reject) => {		  
		  let promiseArray = [];
		  promiseArray.push(this.partService.postMethodData(url, payload)); 
		  Promise.all(promiseArray).then(result => {
        console.log(result);
        if(result.length > 0){
				  if(result[0].code == 200){
            $('#createAccount').modal('hide');
            this.commonService.showSuccess('successfully Create Account.');	
				  }else 
				  this.commonService.showError('Error connecting to server');  
				}
				resolve('done');

      }).catch(err => {
          console.log(err);
          this.commonService.setLoader(false);
          this.commonService.showError('Error connecting to server');
          reject(err);
          }) 
    }); 
  }

resetPassword(){
	 $('.toastr').hide();
	 
	if(this.objUser.USER_ID.trim() == ''){ 
		this.commonService.showError('Enter Email ID.');
		return;
	}
	 
	var url = URL.requestAdminPasswordReset;
		var payload = this.objUser;
		this.commonService.setLoader(true);
		this.commonService.postData(url,payload).subscribe((data) => {
			this.commonService.setLoader(false);
			if(data.code == 200){	
        console.log("success : result : ", data); 
		if(data.message == 'success'){
			this.commonService.showSuccess('Password reset link  sent to your email address. Please check your inbox for more details. '); 
			$('#resetPassword').modal('hide');
		}else 
			this.commonService.showError('Email ID not found.'); 
			}else this.commonService.showError("Error updating password.");
		
    }, err => {
      this.commonService.setLoader(false);
      this.commonService.showError("Error updating password.");
    })	
 }	
  
}

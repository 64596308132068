export class Filter {
     type : string = '';
	 value : string = '';
	 text : string = '';
}


export class Publication {
     PART_NO : string = '';
	 PART_MOD : string = '';
	 DOC_NO : string = '';
	 DOV_REV : string = '';
	 DT_DOC_REV : string = '';
	 DOC_NAME : string = '';
	 DOC_CLASS : string = '';
	 DOC_TITLE : string = '';
	 
}

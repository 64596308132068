<div class="col-md-12 outer-box vh" >
        <img src="/assets/gables-color-logo-400.png" >
        
        <div class="card col-md-12 ">
        <div class="card col-md-6" style="margin: 0 auto; position: relative;">
            <h2 class="card-header primary-color">Welcome Admin</h2>
            <p>To log in, enter your Email ID and password</p>
            <p><abbr class="required" >*</abbr>Indicates required field</p>
            <div class="card-body"> 
				<form #f="ngForm" (ngSubmit)="onSubmit()" novalidate>
                    <div class="form-group">
                        <label for="username" class="control-label">Email ID 
                        <abbr class="required" >*</abbr></label>
                        <input type="text" [(ngModel)]="objUser.USER_ID" name="username" class="form-control"   /> 
                    </div>
                    <div class="form-group">
                        <label for="password" class="control-label">Password 
                        <abbr class="required" >*</abbr></label>
                        <input type="password" name="password" class="form-control" [(ngModel)]="objUser.PASSWORD"   /> 
                    </div>
                    <button  class="btn btn-primary primary-bg"  > 
                        Login
                    </button>
				</form>
                  
					<div class="login-links">			  
						
					<!-- <a href="javascript:void(0)" (click)="createAccount()">New Account</a> |  -->
					<a href="javascript:void(0)" (click)="forgotPassword()" class="primary-color">Forgot Password</a> | <a href="javascript:void(0)" class="primary-color" >Help</a>
					</div>
            </div>
        </div>
        </div>
    </div>

    
<div class="modal" id="createAccount" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
		<h4 class="modal-title">Registration Form</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>          
        </div>
        <div class="modal-body">	
		<div class="col-md-6">
			  <label class="control-label " for="new_user_id">User ID</label>
			  <div class="col-md-10">
				<input type="textbox" name="new_user_id" class="form-control" [(ngModel)]="objUser.USER_ID"   /> 
			</div>
		</div>
		<div class="col-md-6">
			  <label class="control-label " for="new_pass">New Password</label>
			  <div class="col-md-10">
				<input type="password" name="new_pass" class="form-control" [(ngModel)]="objUser.PASSWORD"   /> 
			</div>
		</div>
		<div class="col-md-6">
			  <label class="control-label " for="new_confirm_pass">Confirm Password</label>
			  <div class="col-md-10">
				<input type="password" name="new_confirm_pass" class="form-control" [(ngModel)]="confirmPass"   /> 
			</div>
		</div>
		<div class="col-md-6">
			  <label class="control-label" for="new_email_id">Email Address <i>(Corporate email only)</i></label>
			  <div class="col-md-10">
				<input type="textbox" name="new_email_id" class="form-control" [(ngModel)]="objUser.EMAIL_ID"   /> 
			</div>
		</div>
		<div class="col-md-6">
			  <label class="control-label " for="new_user_name">First Name</label>
			  <div class="col-md-10">
				<input type="textbox" name="firstName" class="form-control" [(ngModel)]="objUser.FIRST_NAME"   /> 
			</div>
		</div>
		<div class="col-md-6">
			  <label class="control-label " for="new_user_name">Middle Initial</label>
			  <div class="col-md-10">
				<input type="textbox" name="middleName" class="form-control" [(ngModel)]="objUser.MIDDLE_NAME"   /> 
			</div>
		</div>
		<div class="col-md-6">
			  <label class="control-label " for="new_user_name">Last Name</label>
			  <div class="col-md-10">
				<input type="textbox" name="lastName" class="form-control" [(ngModel)]="objUser.LAST_NAME"   /> 
			</div>
		</div>
		<div class="col-md-12">
			  <label class="control-label " for="new_user_name">Terms and Conditions</label> 
				<textarea rows="7"  [(ngModel)]="termsConditions" name="new_user_name" readonly class="form-control">
				</textarea>
		</div>
		<div class="col-md-12">
			  
			  <div class="col-md-10">
				<input type="checkbox" name="agree" [(ngModel)]="agree" /> 
				<label class="control-label" for="new_user_name">I agree with Terms and Conditions</label>
			</div>
		</div>
		
        </div>
        <div class="modal-footer">
		 <button type="button" class="btn btn-primary"(click)="submitNewAccount()" >Submit</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>

  
<div class="modal" id="resetPassword" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
		<h4 class="modal-title">Reset Password</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>          
        </div>
        <div class="modal-body"> 
		<!-- <div class="col-md-4"> -->
			  <!-- <label class="control-label" for="new_email_id">Email ID</label> -->
			  <!-- <div class="col-md-10"> -->
				<!-- <input type="textbox" name="new_email_id" class="form-control" [(ngModel)]="objUser.EMAIL_ID"   />  -->
			<!-- </div> -->
		<!-- </div> -->
          <div class="col-md-6">
			  <label class="control-label " for="new_user_id">Email ID</label>
			  <div class="col-md-10">
				<input type="textbox" name="new_user_id" class="form-control" [(ngModel)]="objUser.USER_ID"   /> 
			</div>
		</div> 
		<!-- <div class="col-md-4"> -->
			  <!-- <label class="control-label " for="new_pass">Password</label> -->
			  <!-- <div class="col-md-10"> -->
				<!-- <input type="password" name="new_pass" class="form-control" [(ngModel)]="objUser.PASSWORD"   />  -->
			<!-- </div> -->
		<!-- </div> -->
		<!-- <div class="col-md-4"> -->
			  <!-- <label class="control-label " for="new_confirm_pass">Confirm Password</label> -->
			  <!-- <div class="col-md-10"> -->
				<!-- <input type="password" name="new_confirm_pass" class="form-control" [(ngModel)]="confirmPass"   />  -->
			<!-- </div> -->
		<!-- </div> -->
		
        </div>
        <div class="modal-footer">
		 <button type="button" class="btn btn-primary primary-bg"(click)="resetPassword()" >Submit</button>
          <button type="button" class="btn btn-primary primary-bg" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>  
 

  
    
     

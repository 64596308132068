import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
declare var $ :any;
// import { PartListComponent } from '../customer/components/part-list/part-list.component';

@Injectable({
  providedIn: 'root'
})
export class PartService {
 
  constructor(private http: HttpClient) { }
  // GetPartList(): Observable<PartListComponent[]> {
    // return this.http.get<PartListComponent[]>(this.Url + '/Getpart');
  // }


  postData(url, data): Observable<any> { 
    $('.toastr').hide();
      let userDetails = '';
      userDetails = localStorage.getItem('authTokenAdmin'); 
  
      var jsonString = JSON.stringify(data);
    if(jsonString != undefined)
      jsonString = jsonString.replace("'", "''");
      data = JSON.parse(jsonString); 
    
    if(userDetails == undefined || userDetails == null){
      return this.http.post(url, data).do(data => {  
      });
    }else{
      let headers = new HttpHeaders({ 
      'Authorization': userDetails });
      let options = { headers: headers };
      return this.http.post(url, data,options).do(data => {  
      });
    }
    }
  adminLoiginData(url, data): Observable<any> { 
  $('.toastr').hide();
    let userDetails = '';
    userDetails = localStorage.getItem('authTokenAdmin'); 

    var jsonString = JSON.stringify(data);
    if(jsonString != undefined)
      jsonString = jsonString.replace("'", "''");
      data = JSON.parse(jsonString); 
    
    if(userDetails == undefined || userDetails == null){
      return this.http.post(url, data).do(data => {  
      });
    }else{
      let headers = new HttpHeaders({ 
      'Authorization': userDetails });
      let options = { headers: headers };
      return this.http.post(url, data,options).do(data => {  
      });
    }
  }
  getMethodData(url){
	  $('.toastr').hide();
    let userDetails = '';
		userDetails = localStorage.getItem('authTokenAdmin'); 
		console.log(userDetails);
		if(userDetails == undefined || userDetails == null){
      return this.http.get(url).toPromise();
		}else{
			let headers = new HttpHeaders({ 
			'Authorization': userDetails });
      let options = { headers: headers };
      return this.http.get(url, options).toPromise();
		}
  }
  postMethodData(url, data){
	  $('.toastr').hide();
    let userDetails = '';
    userDetails = localStorage.getItem('authTokenAdmin'); 
    
    var jsonString = JSON.stringify(data);
	if(jsonString != undefined)
    jsonString = jsonString.replace("'", "''");
    data = JSON.parse(jsonString); 
		
		if(userDetails == undefined || userDetails == null){
      return this.http.post(url,data).toPromise();
		}else{
			let headers = new HttpHeaders({ 
			'Authorization': userDetails });
      let options = { headers: headers };
      return this.http.post(url,data, options).toPromise();
		}
  }
  putMethodData(url, data){
	  $('.toastr').hide();
    let userDetails = '';
		userDetails = localStorage.getItem('authTokenAdmin'); 
		
		if(userDetails == undefined || userDetails == null){
      return this.http.put(url,data).toPromise();
		}else{
			let headers = new HttpHeaders({ 
			'Authorization': userDetails });
      let options = { headers: headers };
      return this.http.put(url,data, options).toPromise();
		}
  }
  deleteMethodData(url){
	  $('.toastr').hide();
    let userDetails = '';
		userDetails = localStorage.getItem('authTokenAdmin'); 
		
		if(userDetails == undefined || userDetails == null){
      return this.http.delete(url).toPromise();
		}else{
			let headers = new HttpHeaders({ 
			'Authorization': userDetails });
      let options = { headers: headers };
      return this.http.delete(url, options).toPromise();
		}
  }
}

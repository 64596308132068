import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL } from '../urls'; 
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
 
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationAdminService {
	currentUser : User;
   // private currentUserSubject: BehaviorSubject<User>;
   // public currentUser: Observable<User>;

    constructor(private router: Router,private http: HttpClient ) {
        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        //this.currentUser = this.currentUserSubject.asObservable();
    }

    getCurrentUser(): User {
        //this.currentUserSubject.value;
		this.currentUser = JSON.parse(localStorage.getItem('currentAdminUser'));
		return this.currentUser;
    }
	
	setCurrentUser(user:User) { 
		localStorage.setItem('currentAdminUser', JSON.stringify(user));
		localStorage.setItem('authTokenAdmin', user.token);
		sessionStorage.setItem('loggedIn', 'true');
         //this.currentUserSubject.next(user);
    }
	
 

    logout(module:String) {
        // remove user from local storage to log user out
        localStorage.removeItem('currentAdminUser');
       localStorage.removeItem('authTokenAdmin');
	   sessionStorage.setItem('loggedIn', 'false');
		this.navigateToLogin(module);

       // this.currentUserSubject.next(null);
    }
	
	navigateToLogin(module: String){
        console.log(module);
		if(module == 'ADMIN')
			this.router.navigate(['admin']);
		else
			this.router.navigate(['admin']);
	}
	
	canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> { 
   
   if(sessionStorage.getItem('loggedIn') != 'true'){
			this.logout('ADMIN');		  
		  return Observable.of(false);
	  }
	  
    var user = this.getCurrentUser();
    if (user== undefined || user == null) 
	    this.navigateToLogin('ADMIN');
    else if (user.token== undefined || user.token == null) 
	    this.navigateToLogin('ADMIN');
    else
    return Observable.of(true);
    
  }
  
  HasAccess(){
	  var retVal = true;
	   var user = this.getCurrentUser();
    if (user== undefined || user == null)  retVal = false;
	else if(user.ACCESS_APPROVED == '0')   retVal = false;
	else if(user.CUSTOMER_ID == '')   retVal = false;
		
	return retVal;
  }
  
  canActivateAdmin(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> { 
   
   var user = this.getCurrentUser();
   if (user== undefined || user == null) 
	   this.navigateToLogin('ADMIN');
   else if (user.token== undefined || user.token == null) 
	   this.navigateToLogin('ADMIN');
   else
    return Observable.of(true);
    
  }
}